<!--
 * @Author: jianying.wan
 * @Date: 2019-12-12 09:23:00
 * @LastEditors  : jianying.wan
 * @LastEditTime : 2019-12-20 09:44:17
 * @Description: 停车优惠券
 -->
<template>
  <div class="CarParkCouponList-wrapper">
    <list ref="list"
          exportPermission="export"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          :headers="headers">
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"/>
      </template>
      <template #searchSlot>
        <v-input label="优惠券名称"
                 v-model="searchParams.couponName"></v-input>
        <v-select label="优惠券类型"
                  v-model="searchParams.couponType"
                  :options="typeOps"></v-select>
        <!--<v-select2 label="所属组织"-->
        <!--           v-model="searchParams.orgId"-->
        <!--           v-bind="orgParams" />-->
        <v-select label="状态"
                  v-model="searchParams.couponStatus"
                  :options="statusOps"></v-select>
        <v-select2 label="所属项目"
                   v-model="searchParams.communityId"
                   v-bind="communityParams" />
        <v-select2 label="停车场"
                   v-model="searchParams.carParkId"
                   v-bind="parkingParams"
                   :disabled="!(searchParams.communityId && searchParams.communityId.length)"
                   :subjoin="parkSubjoin"></v-select2>
        <v-select2 label="所属房号" :disabled="!(searchParams.communityId && searchParams.communityId.length)" v-model="searchParams.houseId" placeholder="查询房号" v-bind="roomIdConfig" :subjoin="communityIdParams"></v-select2>
        <v-datepicker label="创建时间"
                      :startTime.sync="searchParams.intimeStart"
                      :endTime.sync="searchParams.intimeEnd"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑"
                  type="text"
                  @click="edit(scope.row)"></v-button>
        <v-button text="发放记录"
                  type="text"
                  @click="recordList(scope.row)"></v-button>
      </template>
      <template #batchSlot="scope">
      </template>
    </list>
  </div>
</template>

<script>
import { orgParams, communityParams } from 'common/select2Params'
import { getCouponListURL, exportCouponURL, parkingSelect2URL, select2RoomIdUrl } from './api'
import { setStatusOps,
  setTypeOps } from './map'

export default {
  name: 'CarParkCouponList',
  data () {
    return {
      searchUrl: getCouponListURL,
      exportUrl: exportCouponURL,
      communityParams,
      orgParams,
      parkingParams: {
        searchUrl: parkingSelect2URL,
        request: {
          text: 'carParkName',
          value: 'carParkId'
        },
        response: {
          text: 'parkingName'
        }
      },
      statusOps: setStatusOps(1),
      typeOps: setTypeOps(1),
      searchParams: {
        couponName: '',
        couponType: undefined,
        couponStatus: undefined,
        orgId: '',
        houseId: '',
        communityId: '',
        intimeStart: undefined,
        intimeEnd: undefined,
        carParkId: ''
      },
      headers: [
        {
          prop: 'couponNumber',
          label: '券池编号'
        },
        {
          prop: 'couponName',
          label: '优惠券名称'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'carParkName',
          label: '停车场'
        },
        {
          prop: 'houseNum',
          label: '所属房号'
        },
        {
          prop: 'couponType',
          label: '优惠券类型'
        },
        {
          prop: 'price',
          label: '单张金额（元）'
        },
        {
          prop: 'couponDuration',
          label: '单张时长（小时）'
        },
        {
          prop: 'endTime',
          label: '有效期截止时间'
        },
        {
          prop: 'totalQuantity',
          label: '总数'
        },
        {
          prop: 'issueQuantity',
          label: '已发放数'
        },
        {
          prop: 'couponStatus',
          label: '状态'
        },
        {
          prop: 'intime',
          label: '创建时间'
        },
        {
          prop: 'updateTime',
          label: '更新时间'
        }
      ],
        roomIdConfig: {
            searchUrl: select2RoomIdUrl,
            request: {
                text: 'address',
                value: 'id'
            },
            response: {

            }
        },
    }
  },
  computed: {
    parkSubjoin () {
      return { communityId: this.searchParams.communityId }
    },
      communityIdParams () {
          return {
              communityId: this.searchParams.communityId,
          }
      },
  },
  methods: {
    create () {
      this.$router.push({
        name: 'carParkCouponForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'carParkCouponForm',
        query: {
          couponNumber: row.couponNumber
        }
      })
    },
    recordList (row) {
      this.$router.push({
        name: 'releaseRecord',
        query: {
          couponPoolNumber: row.couponNumber
        }
      })
    }
  }
}
</script>
